import request from '@/utils/request'

export function getShortages(params) {
  return request({
    method: 'get',
    url: 'shortage',
    params: params
  })
}

export function checkShortage(data) {
  return request({
    method: 'post',
    url: `shortage/${data.id}/check`,
    data
  })
}
