<template>
  <div class="shortage-check-container">
    <my-nav-bar
      title="缺货审核"
      left-text="返回"
    />
    <div class="app-main-container">
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        @load="loadData"
      >
        <van-empty v-if="showEmpty" description="暂无数据" />
        <van-swipe-cell v-for="(goods, index) in list" :key="index">
          <van-cell :title="goods.picker.name || ''" :value="goods.created_at" />
          <van-cell :title="$$(goods, 'order_goods', 'order', 'line', 'name') + '线'" :value="$$(goods, 'order_goods', 'order', 'client', 'name')" />
          <div class="goods">
            <div class="image">
              <van-image width="2.1333rem" height="2.1333rem" :src="goods.image" />
            </div>
            <div class="content">
              <div class="name">{{ goods.name }}</div>
              <div class="size">
                <span style="margin-right: 15px;">规格：1 * {{ goods.size }}</span>
                <span v-if="goods.default_in_warehouse && goods.default_in_warehouse.warehouse">储位：{{ goods.default_in_warehouse.warehouse.name + ' ' + goods.default_in_warehouse.storage }}</span>
              </div>
              <div class="volume">缺货数量：{{ goods.volume }}</div>
            </div>
          </div>
          <template #right>
            <van-button square type="primary" text="审核" @click="handleCheck(goods)" />
          </template>
        </van-swipe-cell>
      </van-list>
    </div>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="selectAction" />
  </div>
</template>

<script>
import { getShortages, checkShortage } from '@/api/shortage_check'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'ShortageCheck',
  components: { myNavBar },
  data() {
    return {
      list: [],
      listQuery: {
        limit: 10,
        page: 0
      },
      loading: false,
      finished: false,
      showEmpty: false,
      showActionSheet: false,
      actions: [
        { name: '有货', value: 0 },
        { name: '缺货', value: 1 },
        { name: '厂缺', value: 2 }
      ],
      checkGoods: null
    }
  },
  methods: {
    loadData() {
      this.listQuery.page++
      getShortages(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    handleCheck(goods) {
      this.showActionSheet = true
      this.checkGoods = goods
    },
    selectAction(action) {
      this.showActionSheet = false
      this.confirm(`确定商品${action.name}吗？`).then(() => {
        let data
        if (action.value < 2) {
          data = { id: this.checkGoods.id, is_shortage: action.value }
        } else {
          data = { id: this.checkGoods.id, is_shortage: 1, is_supplier_shortage: 1 }
        }
        this.beginLoad()
        checkShortage(data).then(res => {
          this.success(res.msg)
          this.reload()
        })
      })
    },
    reload() {
      this.list = []
      this.listQuery.page = 0
      this.loading = false
      this.finished = false
      this.$refs.list.check()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/color";
.shortage-check-container {
  padding: 15px 15px 0 15px;
  .van-swipe-cell__right .van-button {
      height: 100%!important;
    }
  .goods {
    background-color: #fff;
    margin-bottom: 15px;
    border-radius: 5px;
    display: flex;
    padding: 10px;
    .content {
      padding-left: 10px;
      .name {
        font-size: 15px;
      }
      .size {
        font-size: 12px;
        color: #969799;
      }
      .volume {
        font-size: 13px;
        color: $amountRedColor;
      }
    }
  }
}
</style>
