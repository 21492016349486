var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "shortage-check-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "缺货审核",
      "left-text": "返回"
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-list', {
    ref: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.loadData
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (goods, index) {
    return _c('van-swipe-cell', {
      key: index,
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            attrs: {
              "square": "",
              "type": "primary",
              "text": "审核"
            },
            on: {
              "click": function click($event) {
                return _vm.handleCheck(goods);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "title": goods.picker.name || '',
        "value": goods.created_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": _vm.$$(goods, 'order_goods', 'order', 'line', 'name') + '线',
        "value": _vm.$$(goods, 'order_goods', 'order', 'client', 'name')
      }
    }), _c('div', {
      staticClass: "goods"
    }, [_c('div', {
      staticClass: "image"
    }, [_c('van-image', {
      attrs: {
        "width": "2.1333rem",
        "height": "2.1333rem",
        "src": goods.image
      }
    })], 1), _c('div', {
      staticClass: "content"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(goods.name))]), _c('div', {
      staticClass: "size"
    }, [_c('span', {
      staticStyle: {
        "margin-right": "15px"
      }
    }, [_vm._v("规格：1 * " + _vm._s(goods.size))]), goods.default_in_warehouse && goods.default_in_warehouse.warehouse ? _c('span', [_vm._v("储位：" + _vm._s(goods.default_in_warehouse.warehouse.name + ' ' + goods.default_in_warehouse.storage))]) : _vm._e()]), _c('div', {
      staticClass: "volume"
    }, [_vm._v("缺货数量：" + _vm._s(goods.volume))])])])], 1);
  })], 2)], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.selectAction
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }